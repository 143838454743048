// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-accomplishments-awards-jsx": () => import("./../../../src/pages/accomplishments-awards.jsx" /* webpackChunkName: "component---src-pages-accomplishments-awards-jsx" */),
  "component---src-pages-club-coach-comments-jsx": () => import("./../../../src/pages/club-coach-comments.jsx" /* webpackChunkName: "component---src-pages-club-coach-comments-jsx" */),
  "component---src-pages-high-school-coach-comments-jsx": () => import("./../../../src/pages/high-school-coach-comments.jsx" /* webpackChunkName: "component---src-pages-high-school-coach-comments-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-softball-highlights-jsx": () => import("./../../../src/pages/softball-highlights.jsx" /* webpackChunkName: "component---src-pages-softball-highlights-jsx" */),
  "component---src-pages-softball-schedule-jsx": () => import("./../../../src/pages/softball-schedule.jsx" /* webpackChunkName: "component---src-pages-softball-schedule-jsx" */),
  "component---src-pages-wrestling-highlights-jsx": () => import("./../../../src/pages/wrestling-highlights.jsx" /* webpackChunkName: "component---src-pages-wrestling-highlights-jsx" */)
}

